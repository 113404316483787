import React from "react";

interface Props {
  id: string;
}

export const GlobalSvgSelector = ({ id }: Props) => {
  switch (id) {
    case "close":
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_11_99)">
            <path d="M17.25 8.25C16.836 8.25 16.5 8.586 16.5 9C16.5 13.1355 13.1355 16.5 9 16.5C4.8645 16.5 1.5 13.1355 1.5 9C1.5 4.8645 4.8645 1.5 9 1.5C11.0134 1.5 12.9034 2.28675 14.322 3.7155C14.6138 4.00912 15.0889 4.011 15.3825 3.71925C15.6765 3.4275 15.678 2.95238 15.3862 2.65838C13.6841 0.94425 11.4157 0 9 0C4.03725 0 0 4.03725 0 9C0 13.9628 4.03725 18 9 18C13.9624 18 18 13.9628 18 9C18 8.586 17.664 8.25 17.25 8.25Z" fill="#4793FF" />
            <path d="M10.0605 9L11.7803 7.28025C12.0731 6.98737 12.0731 6.51262 11.7803 6.21975C11.4874 5.92687 11.0126 5.92687 10.7198 6.21975L9.00003 7.9395L7.28028 6.21975C6.98778 5.92687 6.51228 5.92687 6.21978 6.21975C5.9269 6.51262 5.9269 6.98737 6.21978 7.28025L7.93953 9L6.21978 10.7198C5.9269 11.0126 5.9269 11.4874 6.21978 11.7803C6.36603 11.9269 6.55803 12 6.75003 12C6.94203 12 7.13403 11.9269 7.28028 11.7803L9.00003 10.0605L10.7198 11.7803C10.8664 11.9269 11.058 12 11.25 12C11.442 12 11.6336 11.9269 11.7803 11.7803C12.0731 11.4874 12.0731 11.0126 11.7803 10.7198L10.0605 9Z" fill="#4793FF" />
          </g>
          <defs>
            <clipPath id="clip0_11_99">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "profile":
      return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_13031_109)">
            <circle cx="16" cy="16" r="16" fill="white" />
            <path d="M16 5C13.5156 5 11.5 7.11161 11.5 9.71429C11.5 12.317 13.5156 14.4286 16 14.4286C18.4844 14.4286 20.5 12.317 20.5 9.71429C20.5 7.11161 18.4844 5 16 5ZM13.75 16C10.0117 16 7 19.1551 7 23.0714V23.8571C7 25.6004 8.33594 27 10 27H22C23.6641 27 25 25.6004 25 23.8571V23.0714C25 19.1551 21.9883 16 18.25 16H13.75Z" fill="#2E3436" />
          </g>
          <defs>
            <clipPath id="clip0_13031_109">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "chevron":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_13031_112)">
            <path d="M6.7075 8.91749C7.09802 8.52697 7.73158 8.52657 8.12211 8.9171L11.2929 12.0879C11.6834 12.4784 12.3166 12.4784 12.7071 12.0879L15.8779 8.9171C16.2684 8.52657 16.902 8.52697 17.2925 8.91749C17.683 9.30802 17.6834 9.94157 17.2929 10.3321L12.7071 14.9179C12.3166 15.3084 11.6834 15.3084 11.2929 14.9179L6.70711 10.3321C6.31658 9.94157 6.31698 9.30802 6.7075 8.91749Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_13031_112">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return null;
  }
};